import { useState } from 'react';
import Multiselect from '@rio-cloud/rio-uikit/Multiselect';
import { validateStates } from './stateValidator';
import { type ActivationInfo, ProductState, type ResourceActivation } from './types';
import { DisplayValidationError } from './DisplayValidationErrors';
import { ConditionallyEnabledButton } from './ConditionallyEnabledButton';

interface Props {
    activationItems: ActivationInfo[];
    disabledText?: string;
}

export const ValidationSection = ({ activationItems, disabledText }: Props) => {
    const [validationResult, setValidationResult] = useState<ResourceActivation[] | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [desiredProductStates, setDesiredProductStates] = useState<ProductState[]>([]);
    return (
        <div className={'margin-20'}>
            <span className={'margin-bottom-20 pull-left'}>
                <ConditionallyEnabledButton
                    onClick={() =>
                        validateStates(activationItems, setValidationResult, desiredProductStates, setIsLoading)
                    }
                    disabledText={disabledText}
                >
                    Validate state in db
                </ConditionallyEnabledButton>
            </span>
            <ProductStateMultiSelect
                desiredProductStates={desiredProductStates}
                setDesiredProductStates={setDesiredProductStates}
            />
            <DisplayValidationError
                validationResult={validationResult?.filter(validation => validation.error)}
                isLoading={isLoading}
                expanderText={'Validation result for selected states'}
                loadingText={'Validating state in the system...'}
                startText={'No Validation happened yet'}
                noErrorText={'All resources are in the expected state'}
            />
        </div>
    );
};

export function ProductStateMultiSelect(props: {
    desiredProductStates: ProductState[];
    setDesiredProductStates: (value: ProductState[]) => void;
}) {
    return (
        <div className={'form-group'}>
            <label>{''}</label>
            <Multiselect
                options={Object.keys(ProductState).map(it => ({
                    id: it,
                    label: it,
                    selected: props.desiredProductStates.includes(it as ProductState),
                }))}
                useFilter
                onChange={selectedOptions => props.setDesiredProductStates(selectedOptions as ProductState[])}
                placeholder={'Select desired product states here'}
            />
        </div>
    );
}
