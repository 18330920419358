import { configureStore } from '@reduxjs/toolkit';
import configReducer from './configSlice';
import loginReducer from '../login/loginSlice';
import tokenReducer from '../tokenHandling/tokenSlice';
import apiReducer from '../../features/app/api/redux/api.redux';
import { packageActivationApiSlice } from '../../features/app/api/packageActivation/packageActivationApiSlice';

export const store = configureStore({
    reducer: {
        config: configReducer,
        login: loginReducer,
        tokenHandling: tokenReducer,
        api: apiReducer,
        [packageActivationApiSlice.reducerPath]: packageActivationApiSlice.reducer,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(packageActivationApiSlice.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type RootDispatch = typeof store.dispatch;
