import { getMarketplaceBackend } from '../../config/appConfig';
import { getRequest } from '../requests';
import { decodeJson, jsonOrReject } from '../api';
import { type ApiBookableResources, ApiBookableResourcesCodec } from './activation.types';
import type { BookableInfo } from '../../activation/types';
import { convertApiActivationState } from './convertApiActivationState';

export function fetchBookableInfos(
    accountId: string,
    productId: string,
    level?: string,
    resourceIds?: string
): Promise<BookableInfo[]> {
    const queryString = makeQueryString(level, resourceIds);
    return fetch(
        `${getMarketplaceBackend()}/admin/${accountId}/activations/${productId}/bookable${queryString ? `?${queryString}` : ''}`,
        getRequest()
    )
        .then(jsonOrReject)
        .then(decodeJson(ApiBookableResourcesCodec))
        .then(mapActivationBookableResponse);
}

function makeQueryString(level?: string, resourceIds?: string): string {
    const queryParams = new URLSearchParams('');
    if (level) {
        queryParams.append('level', level);
    }
    if (resourceIds) {
        queryParams.append('resource_ids', resourceIds);
    }
    return queryParams.toString();
}

function mapActivationBookableResponse(apiBookableResources: ApiBookableResources): BookableInfo[] {
    return apiBookableResources.resources.map(resource => ({
        resourceId: resource.resource_id,
        bookable: resource.bookable,
        activationState: convertApiActivationState(resource.activation_state),
        resourceType: resource.resource_type,
        resourceName: resource.resource_name,
        originLevel: resource.origin_level,
        targetLevel: resource.target_level,
        missingRequirements: resource.missing_requirements?.map(requirement =>
            requirement.map(missingFeature => ({
                featureName: missingFeature.feature_name,
                actual: missingFeature.actual,
                expectedValues: missingFeature.expected_values,
                inclusive: missingFeature.inclusive,
            }))
        ),
        hasOpenContract: resource.has_open_contract,
    }));
}
