import type { PackageActivationResponse } from './packageActivationCall';
import type { PackageActivation } from './types';

export function mapPackageActivationsResponse(apiResponse: PackageActivationResponse): PackageActivation[] {
    return apiResponse.package_activations.map(activation => ({
        id: activation.id,
        accountId: activation.account_id,
        assetId: activation.asset_id,
        packageCode: activation.package_code,
        status: activation.status,
        bookingIntentOperationNumber: activation.booking_intent_operation_number,
        startsAt: activation.starts_at,
        endsAt: activation.ends_at,
        lastUpdatedAt: activation.last_updated_at,
        succeededAt: activation?.succeeded_at,
    }));
}
