import * as t from 'io-ts';

export interface ApiSubscribePayload {
    product_id: string;
    resource_ids: string[];
    level?: string;
}

export const API_PRODUCT_STATE_ACTIVE = 'active';
export const API_PRODUCT_STATE_INACTIVE = 'inactive';
export const API_PRODUCT_STATE_PENDING_CANCELLATION = 'pending-cancellation';
export const API_PRODUCT_STATE_PENDING_ACTIVATION = 'pending-activation';

const ApiProductStateCodec = t.union([
    t.literal(API_PRODUCT_STATE_ACTIVE),
    t.literal(API_PRODUCT_STATE_INACTIVE),
    t.literal(API_PRODUCT_STATE_PENDING_CANCELLATION),
    t.literal(API_PRODUCT_STATE_PENDING_ACTIVATION),
]);
export type ApiProductState = t.TypeOf<typeof ApiProductStateCodec>;

export const ApiResourceActivationCodec = t.intersection([
    t.type({
        resource_id: t.string,
        resource_type: t.string,
        activation_state: ApiProductStateCodec,
    }),
    t.partial({
        resource_name: t.string,
        origin_level: t.string,
        target_level: t.string,
    }),
]);

export const ApiResourceActivationsCodec = t.type({
    activations: t.array(ApiResourceActivationCodec),
});
export type ApiResourceActivations = t.TypeOf<typeof ApiResourceActivationsCodec>;

export interface ApiSubscriptionCancelPayload {
    resource_ids: string[];
}

export interface ActivationSummary {
    productId: string;
    amount: number;
}

const ApiActivationSummaryCodec = t.type({
    product_id: t.string,
    amount: t.number,
});

export const ApiActivationsSummaryCodec = t.type({
    activations: t.array(ApiActivationSummaryCodec),
});
export type ApiActivationsSummary = t.TypeOf<typeof ApiActivationsSummaryCodec>;

const ApiBookableResourceMissingFeatureCodec = t.intersection([
    t.type({
        feature_name: t.string,
        expected_values: t.array(t.string),
        inclusive: t.boolean,
    }),
    t.partial({
        actual: t.string,
    }),
]);

const ApiBookableResourceRequirementCodec = t.array(ApiBookableResourceMissingFeatureCodec);

const ApiBookableResourceCodec = t.intersection([
    t.type({
        resource_id: t.string,
        activation_state: ApiProductStateCodec,
        bookable: t.boolean,
        resource_type: t.string,
    }),
    t.partial({
        resource_name: t.string,
        origin_level: t.string,
        target_level: t.string,
        missing_requirements: t.array(ApiBookableResourceRequirementCodec),
        has_open_contract: t.boolean,
    }),
]);

export const ApiBookableResourcesCodec = t.type({
    resources: t.array(ApiBookableResourceCodec),
});

export type ApiBookableResources = t.TypeOf<typeof ApiBookableResourcesCodec>;
