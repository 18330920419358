import {
    API_PRODUCT_STATE_ACTIVE,
    API_PRODUCT_STATE_INACTIVE,
    API_PRODUCT_STATE_PENDING_ACTIVATION,
    API_PRODUCT_STATE_PENDING_CANCELLATION,
    type ApiProductState,
} from './activation.types';
import { ProductState } from '../../activation/types';
import { UnreachableCaseError } from '../../common/utils/typescriptUtil';

export function convertApiActivationState(apiState: ApiProductState): ProductState {
    switch (apiState) {
        case API_PRODUCT_STATE_ACTIVE:
            return ProductState.ACTIVE;
        case API_PRODUCT_STATE_INACTIVE:
            return ProductState.INACTIVE;
        case API_PRODUCT_STATE_PENDING_ACTIVATION:
            return ProductState.PENDING_ACTIVATION;
        case API_PRODUCT_STATE_PENDING_CANCELLATION:
            return ProductState.PENDING_CANCELLATION;
        default:
            throw new UnreachableCaseError(apiState);
    }
}
