import ClearableInput from '@rio-cloud/rio-uikit/ClearableInput';
import ExpanderPanel from '@rio-cloud/rio-uikit/ExpanderPanel';
import { useState } from 'react';
import { ConditionallyEnabledButton } from './ConditionallyEnabledButton';
import { fetchActivationsSummary } from '../api/activation/activationSummaryCall';
import Notification from '@rio-cloud/rio-uikit/Notification';
import type { ActivationSummary } from '../api/activation/activation.types';
import { AccountSummary } from './AccountSummary';

export const ActivationStatusAccount = () => {
    const [accountId, setAccountId] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);
    const [summary, setSummary] = useState<ActivationSummary[] | undefined>();

    return (
        <div className={'margin-20'}>
            <ExpanderPanel title={<h5>Account summary</h5>} bsStyle={'default'}>
                <div>
                    <div className={'form-group'}>
                        <label>{'Account id*'}</label>
                        <ClearableInput
                            placeholder='Insert account id'
                            value={accountId}
                            onChange={(accountId: string) => setAccountId(accountId.trim())}
                        />
                    </div>
                </div>
                <div className={'height-40'}>
                    <ConditionallyEnabledButton
                        onClick={async () => {
                            setSummary(undefined);
                            setIsLoading(true);
                            try {
                                setSummary(await fetchActivationsSummary(accountId));
                                setIsLoading(false);
                            } catch (e) {
                                setIsLoading(false);
                                Notification.error(
                                    `Could not fetch activations for account ${accountId}. Does the account exist?`
                                );
                            }
                        }}
                        disabledText={!accountId ? 'Please enter an account id' : undefined}
                    >
                        Find activations
                    </ConditionallyEnabledButton>
                </div>
                <div className={'margin-top-20'}>
                    {isLoading ? 'Loading...' : undefined}
                    {summary ? <AccountSummary summary={summary} /> : undefined}
                </div>
            </ExpanderPanel>
        </div>
    );
};
