import * as t from 'io-ts';

export const ActivationInfoSpec = t.intersection([
    t.type({
        accountId: t.string,
        productId: t.string,
        resourceIds: t.array(t.string),
    }),
    t.partial({
        level: t.string,
    }),
]);

export const ActivationInfosSpec = t.array(ActivationInfoSpec);

export type ActivationInfo = t.TypeOf<typeof ActivationInfoSpec>;

export interface BrowserInput {
    items: ActivationInfo[];
    error?: string;
}

export interface AccountListInput {
    accountIds: string[];
}

export interface InputData {
    accountId: string;
    resourceId: string;
    productId: string;
    level?: string;
}

export enum ProductState {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    PENDING_CANCELLATION = 'PENDING_CANCELLATION',
    PENDING_ACTIVATION = 'PENDING_ACTIVATION',
}

export interface ResourceActivation {
    resourceId?: string;
    productState?: ProductState;
    accountId: string;
    productId: string;
    level?: string;
    error?: string;
}

export interface OverviewResource {
    id: string;
    name: string;
    productState: ProductState;
    originLevel?: string;
    targetLevel?: string;
}

export interface BookableInfo {
    resourceId: string;
    activationState: ProductState;
    bookable: boolean;
    resourceType: string;
    resourceName?: string;
    originLevel?: string;
    targetLevel?: string;
    missingRequirements?: Array<
        Array<{
            featureName: string;
            actual?: string;
            expectedValues: string[];
            inclusive: boolean;
        }>
    >;
    hasOpenContract?: boolean;
}

export interface FreeTrialEligible {
    status: boolean;
}
