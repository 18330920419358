import { getMarketplaceBackend } from '../../config/appConfig';
import { getRequest } from '../requests';
import { decodeJson, jsonOrReject } from '../api';
import { type ActivationSummary, type ApiActivationsSummary, ApiActivationsSummaryCodec } from './activation.types';

export function fetchActivationsSummary(accountId: string): Promise<ActivationSummary[]> {
    return fetch(`${getMarketplaceBackend()}/admin/${accountId}/activations/summary`, getRequest())
        .then(jsonOrReject)
        .then(decodeJson(ApiActivationsSummaryCodec))
        .then(mapActivationsSummaryResponse);
}

function mapActivationsSummaryResponse(apiActivationsSummary: ApiActivationsSummary): ActivationSummary[] {
    return apiActivationsSummary.activations.map(it => ({ productId: it.product_id, amount: it.amount }));
}
