import { type ActivationInfo, type ResourceActivation, ProductState } from '../types';
import { useEffect, useState } from 'react';
import { validateStates } from '../stateValidator';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import { DisplayValidationError } from '../DisplayValidationErrors';
import { DisplayActivationStates } from '../DisplayActivationStates';

export function ResetDialog(props: {
    activationItems: ActivationInfo[];
    resetting: boolean;
    onClickCancel: () => void;
    onClickConfirm: () => Promise<void>;
}) {
    const [validationResult, setValidationResult] = useState<ResourceActivation[] | undefined>(undefined);
    const [isValidating, setIsValidating] = useState<boolean>(false);
    const showValidationWarning = validationResult?.some(validation => validation.error);

    useEffect(() => {
        setIsValidating(true);
        validateStates(
            props.activationItems,
            setValidationResult,
            [ProductState.PENDING_ACTIVATION, ProductState.ACTIVE],
            setIsValidating
        );
    }, []);

    return (
        <ConfirmationDialog
            show={true}
            content={
                <div>
                    Do you really want to reset the following activations?
                    {showValidationWarning && (
                        <div className='panel panel-warning margin-top-10'>
                            <div className='panel-heading'>
                                <div className='panel-title'>No activation found for some of the resources</div>
                            </div>
                        </div>
                    )}
                    <div className={'margin-top-20 max-height-75vh overflow-auto'}>
                        {showValidationWarning ? (
                            <DisplayValidationError
                                validationResult={validationResult}
                                isLoading={isValidating}
                                expanderText={'Validation result'}
                                loadingText={'Validating state in the system...'}
                                startText={'No Validation happened yet'}
                                noErrorText={'All resources are in the expected state'}
                            />
                        ) : (
                            <DisplayActivationStates
                                validationResult={validationResult}
                                isLoading={isValidating}
                                expanderText={'Current states of activations'}
                                loadingText={'Validating state in the system...'}
                                noItemsText={'No resource activations found'}
                            />
                        )}
                    </div>
                </div>
            }
            confirmButtonText={'Reset'}
            cancelButtonText={'Close'}
            disableConfirm={isValidating || props.resetting || showValidationWarning}
            onClickCancel={props.onClickCancel}
            onClickConfirm={props.onClickConfirm}
            title={'Reset activation'}
            useOverflow={false}
        />
    );
}
